<template>
  <div class="auth-page">
    <div class="d-flex fill-width fill-height justify-center align-center">
      <div class="text-center" style="min-width: 300px">
        <span class="font-weight-bold">
          #LAMORINGA
        </span><br>
        <small>- LOGIN DASHBOARD -</small>
        <v-divider class="my-8" />
        <v-text-field
          v-model="login.username"
          label="Username"
          outlined
          hide-details
          class="mb-4"
          @keypress.enter="AUTH_LOGIN"
        />
        <v-text-field
          v-model="login.password"
          label="Password"
          type="password"
          outlined
          hide-details
          class="mb-8"
          @keypress.enter="AUTH_LOGIN"
        />
        <v-divider class="mb-8" />
        <v-btn block large depressed color="indigo darken-2 text-capitalize font-weight-bold" dark @click.prevent="AUTH_LOGIN">
          LOGIN
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    login: {
      username: '',
      password: ''
    }
  }),
  methods: {
    AUTH_LOGIN () {
      this.$store.dispatch('AUTH_LOGIN', this.login)
        .then((res) => {
          if (res) {
            if (res.status) {
              if (res.data.data.response && res.data.data.access_token) {
                this.$store.dispatch('TOAST', { show: true, message: 'Logged In' })
                localStorage.setItem('t', res.data.data.access_token)
                this.$store.dispatch('ME')
              } else {
                this.$store.dispatch('TOAST', { show: true, message: res.data.data.message })
              }
            } else {
              this.$store.dispatch('TOAST', { show: true, message: 'Ops, something went wrong' })
            }
          } else {
            this.$store.dispatch('TOAST', { show: true, message: 'Ops, something went wrong' })
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.auth-page {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 1000000000;
  background: #fff;
}
</style>
